@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: 500;
  src: font-files("Roboto-Medium.woff"); }

.Sessions-oauthHeader {
  padding-top: 16px;
  font-size: 12px;
  font-weight: 600; }

.Sessions-oauthContainer {
  display: flex;
  justify-content: space-between; }
  .Sessions-oauthContainer .Sessions-oauthButton {
    margin-left: 8px; }
  .Sessions-oauthContainer .Sessions-oauthButton:first-child {
    margin-left: 0; }

.Sessions-oauthButton {
  flex: 1;
  width: 100%;
  min-width: 156px;
  max-width: 320px;
  height: 40px;
  padding-left: 52px;
  transition: all 0.15s;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #FFF;
  box-shadow: 0 0.5px rgba(0, 0, 0, 0.2);
  color: #757575;
  font-family: 'Roboto Medium', sans-serif;
  line-height: 40px;
  vertical-align: text-bottom;
  cursor: pointer; }
  .Sessions-oauthButton:hover {
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
    text-decoration: none; }

.Sessions-githubButton {
  background-image: url("/assets/1.0.0-assets.208/images/layout/sessions/GitHub-Mark-32px.png");
  background-repeat: no-repeat;
  background-position: 15px 11px;
  background-size: 18px 18px; }

.Sessions-googleButton {
  background-image: url("/assets/1.0.0-assets.208/images/layout/sessions/g-normal.png");
  background-repeat: no-repeat;
  background-position: 15px 11px;
  background-size: 18px 18px; }
