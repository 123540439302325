.Carrousel-nav {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px 0 10px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000', endColorstr='#99000000', GradientType=0); }

.Carrousel-navItem {
  position: relative;
  margin-left: 5px;
  text-indent: -9000px; }
  .Carrousel-navItem:first-child {
    margin-left: 0; }
  .Carrousel-navItem.is-active .Carrousel-navLink {
    background: #364046; }

.Carrousel-navLink {
  display: block;
  position: relative;
  z-index: 2;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #FFF; }

.Carrousel-slide {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0;
  background-color: #F7F7F7; }
  .Carrousel-slide.is-active {
    -webkit-animation: slideIn 0.8s ease 0.3s;
    animation: slideIn 0.8s ease 0.3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .Carrousel-slide.is-disabled picture,
  .Carrousel-slide.is-disabled img {
    opacity: 0.6; }

.Carrousel-slide--01 {
  background: url("/assets/1.0.0-assets.208/images/layout/signup/slide-01-bg.png") no-repeat; }
  .Carrousel-slide--01.is-active .Carrousel-card {
    position: absolute;
    width: 110px;
    height: 110px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    opacity: 0;
    background-repeat: no-repeat;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

.Carrousel-card--01,
.Carrousel-card--06 {
  background-image: url("/assets/1.0.0-assets.208/images/layout/signup/slide-01-card-01-bg.png"); }

.Carrousel-card--02,
.Carrousel-card--03,
.Carrousel-card--07 {
  background-image: url("/assets/1.0.0-assets.208/images/layout/signup/slide-01-card-02-bg.png"); }

.Carrousel-card--04,
.Carrousel-card--05,
.Carrousel-card--08 {
  background-image: url("/assets/1.0.0-assets.208/images/layout/signup/slide-01-card-03-bg.png"); }

.Carrousel-card--01 {
  top: 160px;
  left: 26px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s; }

.Carrousel-card--02 {
  top: 160px;
  left: 156px;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s; }

.Carrousel-card--03 {
  top: 160px;
  left: 284px;
  -webkit-animation-delay: 1s;
  animation-delay: 1s; }

.Carrousel-card--04 {
  top: 160px;
  left: 412px;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s; }

.Carrousel-card--05 {
  top: 293px;
  left: 28px;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s; }

.Carrousel-card--06 {
  top: 293px;
  left: 156px;
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s; }

.Carrousel-card--07 {
  top: 293px;
  left: 284px;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s; }

.Carrousel-card--08 {
  top: 293px;
  left: 412px;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s; }

.Carrousel-text {
  position: relative;
  height: 44px; }

.Carrousel-textItem {
  position: absolute;
  left: 20px;
  width: 100%;
  -webkit-transition: all 0.8s ease-out;
  transition: all 0.8s ease-out;
  opacity: 0;
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-weight: 200;
  line-height: 42px;
  text-align: center; }
  .Carrousel-textItem.is-active {
    left: 0;
    opacity: 1; }

.Carrousel-textIcon {
  display: inline-block;
  width: 42px;
  height: 42px;
  margin-right: 20px;
  vertical-align: middle; }

.Carrousel-slide--02 {
  background: url("/assets/1.0.0-assets.208/images/layout/signup/slide-02-bg.png") no-repeat; }
  .Carrousel-slide--02.is-active .Carrousel-slide--02-inner {
    opacity: 1;
    background-position: center; }

.Carrousel-slide--02-inner,
.Carrousel-slide--04-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.4s ease-out 1s;
  transition: all 0.4s ease-out 1s;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-position: center 0; }

.Carrousel-slide--02-inner {
  background-image: url("/assets/1.0.0-assets.208/images/layout/signup/slide-02-upload.png"); }

.Carrousel-slide--03 {
  background: url("/assets/1.0.0-assets.208/images/layout/signup/slide-03-bg.png") no-repeat; }
  .Carrousel-slide--03.is-active .Carrousel-point {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .Carrousel-slide--03.is-active .Carrousel-slide--03-inner {
    right: 0; }

.Carrousel-slide--03-inner {
  position: absolute;
  z-index: 1001;
  right: -120px;
  bottom: 0;
  width: 211px;
  height: 330px;
  -webkit-transition: all 0.3s ease-out 1.5s;
  transition: all 0.3s ease-out 1.5s;
  background: url("/assets/1.0.0-assets.208/images/layout/signup/slide-03-editor.png") no-repeat; }

.Carrousel-points {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.Carrousel-point {
  position: absolute;
  z-index: 1000;
  width: 15px;
  height: 15px;
  -webkit-transform: scale(0);
  transform: scale(0);
  border: 2px solid #FFF;
  border-radius: 50%;
  opacity: 0;
  background: #ED9259; }

.Carrousel-point--01 {
  top: 150px;
  left: 150px;
  -webkit-animation-delay: 2.3s;
  animation-delay: 2.3s; }

.Carrousel-point--02 {
  top: 252px;
  left: 124px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s; }

.Carrousel-point--03 {
  top: 332px;
  left: 124px;
  -webkit-animation-delay: 3.3s;
  animation-delay: 3.3s; }

.Carrousel-point--04 {
  top: 274px;
  left: 150px;
  -webkit-animation-delay: 2.8s;
  animation-delay: 2.8s; }

.Carrousel-point--05 {
  top: 304px;
  left: 216px;
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s; }

.Carrousel-point--06 {
  top: 246px;
  left: 216px;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s; }

.Carrousel-point--07 {
  top: 246px;
  left: 246px;
  -webkit-animation-delay: 4s;
  animation-delay: 4s; }

.Carrousel-point--08 {
  top: 238px;
  left: 266px;
  -webkit-animation-delay: 2.3s;
  animation-delay: 2.3s; }

.Carrousel-point--09 {
  top: 216px;
  left: 314px;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s; }

.Carrousel-point--10 {
  top: 180px;
  left: 294px;
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s; }

.Carrousel-slide--04 {
  background: url("/assets/1.0.0-assets.208/images/layout/signup/slide-04-bg.png") no-repeat; }
  .Carrousel-slide--04.is-active .Carrousel-slide--04-inner {
    opacity: 1;
    background-position: center; }

.Carrousel-slide--04-inner {
  background-image: url("/assets/1.0.0-assets.208/images/layout/signup/slide-04-share.png"); }

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateX(-100%);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0%);
    opacity: 1; } }

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    transform: translateX(0%);
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(0);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    transform: scale(0);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes animationProgress {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

@keyframes animationProgress {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

@-webkit-keyframes slideOut {
  0% {
    -webkit-transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-100%);
    opacity: 0; } }

@keyframes slideOut {
  0% {
    transform: translateX(0);
    opacity: 1; }
  100% {
    transform: translateX(-100%);
    opacity: 0; } }
